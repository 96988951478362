/* MapBoxMap.css */

.marker {
    width: 18px;
    height: 18px;
    background-color: rgba(0, 0, 255, 0.6); /* semi-transparent blue background */
    border: 2px solid black; /* black border */
    border-radius: 50%; /* circular shape */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    text-align: center;
}

.mapboxgl-popup-content {
    color: black;
}

.mapboxgl-popup-content h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
}

.mapboxgl-popup-content p {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.map-menu {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 12px;
    width: 220px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
}

.map-menu label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
}

.map-menu select {
    width: 100%;
    padding: 6px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
}

.map-menu select:focus {
    border-color: #3b9ddd;
    outline: none;
    background-color: #ffffff;
}

.map-menu select:hover {
    background-color: #f1f1f1;
}

.map-menu button {
    display: inline-block;
    margin: 8px 0;
    padding: 6px 12px;
    color: #fff;
    background-color: #3b9ddd;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
}

.map-menu button:hover {
    background-color: #307bbd;
}
