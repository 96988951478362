div#legend {
    background-color: var(--color-bg-primary);

    .first {
        color: green;
        opacity: 0.75;
    }
    .next {
        color: yellow;
        opacity: 0.75;
    }
    .last {
        color: red;
        opacity: 0.75;
    }
    .cluster {
        color: rgb(161, 161, 161);
        opacity: 0.75;
    }
}
