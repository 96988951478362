.ag-root .ag-checkbox {
    & div.ag-wrapper.ag-checkbox-input-wrapper {
        height: var(--size-height-xxs);
        width: var(--size-height-xxs);
        min-width: var(--size-height-xxs);
        background-color: var(--control-option-bg);
        border: 1px solid;
        border-color: var(--color-text-primary);
        border-radius: 0;

        &:after {
            content: '';
        }

        &.ag-intermediate {

            &:after {

            }
        }

        &.ag-checked {
            &:after {
                color: var(--color-text-primary);
                font-weight: var(--font-weight-regular);
                position: absolute;
                left: 3px;
                top: 50%;
                pointer-events: none;
                height: 8px;
                width: 4px;
                z-index: 2;
                border-style: solid;
                border-width: 0 2px 2px 0;
                -webkit-transform: translateY(calc(-50% - 1px)) rotate(45deg);
                -moz-transform: translateY(calc(-50% - 1px)) rotate(45deg);
                -ms-transform: translateY(calc(-50% - 1px)) rotate(45deg);
                transform: translateY(calc(-50% - 1px)) rotate(45deg);
                border-color: var(--color-text-primary);
            }
        }
    }
}
