@import '@spglobal/tokens/dist/web/tokens.css';
@import '@spglobal/koi-datepicker/dist/css/index.min.css';

/* Screen reader only text */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.spg-layout .sidebar {
    top: 70px;
    height: calc(100vh - 70px);
}

/*.spg-overlay {*/
/*    z-index: 99999 !important;*/
/*}*/

.cli-overflow-y-hidden {
    overflow-y: hidden;
}

.cli-custom-grid-tooltip {
    border: 1px solid !important;
    border-color: var(--color-text-primary) !important;
    border-radius: var(--size-radius-1) !important;
    background-color: var(--color-bg-primary) !important;
}
