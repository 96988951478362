.loader {
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
}
.loader svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #e74c3c;
    width: 65px;
    height: 65px;
    margin: 10px;
    display: inline-block;
}
