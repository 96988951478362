//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import '@spglobal/css-components/dist/css/yuki-theme.min.css';
//
//body {
//    font-family: var(--font-family-base) !important;
//    color: #000000 !important;
//}

.d-flex {
    display: flex !important;
}

.spg-layout__main {
    padding: 0 !important;

    &.main {
        flex-grow: 1 !important;
        padding: 15px !important;
    }
}

.btn-min-width {
    min-width: 150px !important;
}

.welcome-text {
    font-size: 36px !important;
    text-align: center !important;
    margin: 30px 0 !important;
}
.banner-image {
    background-color: #212c37 !important;
    background-image: url('../../src/components/LandingPage/assets/home-banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 225px;
    width: 100%;
}

.spg-selected {
    background-color: var(--color-bg-hover);
}

.spg-outlined {
    outline: var(--color-base-black) 1px solid;
}

.spg-d-hidden {
    visibility: hidden;
    width: 0;
    height: 0;
}

.cli-autocomplete {
    & > .wrapper {
        background-color: var(--color-bg-primary) !important;
        border-radius: 0 !important;
        border-color: var(--color-border-primary) !important;
        box-shadow: none !important;
        font: var(--font-body-sm) !important;
        color: var(--color-text-primary) !important;

        & > div {
            background-color: var(--color-bg-secondary) !important;
            color: var(--color-text-primary) !important;
            z-index: 500;
            min-height: var(--size-height-base) !important;

            input {
                color: var(--color-text-primary) !important;
            }

            ul > li.selected {
                background-color: var(--color-bg-hover);
            }
        }
    }
    height: var(--size-height-base) !important;
    box-shadow: none !important;
}

.spg-text-warning {
    color: var(--color-state-warning) !important;
}

.ag-overlay-no-rows-center {
    color: var(--color-text-primary) !important;
}

.cli-disabled {
    color: var(--color-text-disabled) !important;
}

.cli-tabs-disabled {
    ul {
        pointer-events: none;
    }
}

.ag-overlay {
    position: relative !important;

    .ag-overlay-no-rows-wrapper {
        padding-top: 0 !important;
    }
}

.ag-body-viewport {
    margin-bottom: var(--size-space-md) !important;
}

.cli-capitalize-first:first-letter {
    text-transform: uppercase;
}

.cli-datatablegrid-min-height {
    .ag-root-wrapper {
        min-height: 220px !important;
    }
}

.cli-vulnerability-graph .xAxis .recharts-layer.recharts-cartesian-axis-tick text {
    transform: translate(-4px, -6px);
}

.cli-action-cell.ag-cell-focus {
    border: none !important;
}

.cli-groups-table-cell .ag-invisible .ag-checkbox-input-wrapper {
    visibility: visible !important;
    pointer-events: none !important;
    border-color: var(--color-text-disabled) !important;
}

.summary-report-header-cell .ag-header-cell-label {
    justify-content: center;
}
